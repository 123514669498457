import React, { useState } from 'react';

export default function Header({onCLick}) {
  let [isOpen, setMenuState] = useState(false);
  return <nav className="w-full flex items-center justify-between flex-wrap bg-white py-8">
    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div className="flex text-sm lg:flex-grow">
        <div className="pl-10 lg:pl-0 w-4/5 items-center flex-shrink-0 text-white mr-6">
          <div className="logo"/>
        </div>
        <div className="w-1/5 block lg:hidden">
          <button onClick={()=>setMenuState(isOpen => {return isOpen ? false : true})} className="flex items-center px-3 py-2 border rounded text-black border-black">
            <svg className="fill-current h-3 w-3 text-black" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
          
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen &&
      <div className="flex lg:hidden px-10 mt-10">
            <div className="w-full flex flex-col text-sm lg:flex-grow divide-y divide-gray divide-solid">
              <div onClick={()=>{onCLick('about')}} className=" cursor-pointer block p-4 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black text-aileron-bold">
                About Us
              </div>
              <div onClick={()=>{onCLick('feature')}} className="cursor-pointer block p-4 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black text-aileron-bold">
                Features
              </div>
              <div onClick={()=>{onCLick('contact')}} className="cursor-pointer block p-4 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black text-aileron-bold">
                Contact Us
              </div>
              <div  className="cursor-pointer block py-4 px-0 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black text-aileron-bold">
                <a href="https://admin.ciergio.com/auth/login" className="cursor-pointer inline-block text-sm ml-5 px-8 py-4 leading-none text-xl rounded text-white hover:text-white hover:bg-accent mt-4 lg:mt-0 bg-accent text-aileron-bold">Login</a>
              </div>
            </div>
          </div>
      }

      <div className="hidden lg:block">
        <div className="text-sm lg:flex-grow">
          <div onClick={()=>{onCLick('about')}} className="cursor-pointer block mt-4 px-4 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black mr-4 text-aileron-bold">
            About Us
          </div>
          <div onClick={()=>{onCLick('feature')}} className="cursor-pointer block mt-4 px-4 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black mr-4 text-aileron-bold">
            Features
          </div>
          <div onClick={()=>{onCLick('contact')}} className="cursor-pointer block mt-4 px-4 lg:inline-block lg:mt-0 text-grey text-xl hover:text-black text-aileron-bold">
            Contact Us
          </div>
          <a href="https://admin.ciergio.com/auth/login" className="cursor-pointer inline-block text-sm ml-5 px-8 py-4 leading-none text-xl rounded text-white hover:text-white hover:bg-accent mt-4 lg:mt-0 bg-accent text-aileron-bold">Login</a>
        </div>
      </div>
    </div>
  </nav>
}